<template>
  <ContactPage msg1="Contact Us"/>
  <!--ContactPage email1 = "getemail1()"/>
  <ContactPage email2 = "getemail2()"/-->
</template>

<script>
import ContactPage from './components/ContactPage.vue';
import '@progress/kendo-theme-default/dist/all.css';
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './scss/styles.scss';
//import configData  from "./custom-config.json"
export default {
  name: 'App',
  components: {
    ContactPage
  },
  methods:{
    openChat: function () {   
        window.open("https://home-c39.nice-incontact.com/incontact/chatclient/chatclient.aspx?poc=06c4cd08-a63d-4e5f-85c9-91e88db5b66b&bu=4601711", "_blank");    
    }
    /*getemail1 () {
      let email1 = process.env.NODE_ENV === 'development' ? configData.config.email1 : configData.configProd.email2;
      return email1;
    },
    getemail2 () {
      let email2 = process.env.NODE_ENV === 'development' ? configData.config.email2 : configData.configProd.email2;
      return email2;
    }*/
  },
  mounted() {    
      //console.log(process.env.NODE_ENV)
      let appState = process.env.NODE_ENV === 'development' ? 'Im in Development Mode' : 'Im in Production Mode';
      console.log(appState);  
      /*let email1 = process.env.NODE_ENV === 'development' ? configData.config.email1 : configData.configProd.email1;
      let email2= process.env.NODE_ENV === 'development' ? configData.config.email2 : configData.configProd.email2;     
      console.log(email1); 
      console.log(email2); */
      
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;  
}
</style>
