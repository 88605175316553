<template>

<div class="crp-content">
    <div class="login-background">
        <div>
            <img id="background-image-fullscreen" src="../assets/WebApp_Background_Farm.png" alt="DFA Dairy Brands">
        </div>
    </div>
    <div class="fixed-top">
    <span class="site-header">DFA Dairy Brands Customer Service</span>
    </div>
    <div class="crp-topnav">
        <a class="brandLogo" href="#">
            <img src="../assets/DFA-Logo-Vert.png" alt="DFA Dairy Brands">
        </a>
    </div>
    <div class="crp-login row justify-content-center initial-credentials-panel">
        <div class="login-form">
            <div class="crp-login-panel pageHeader">                
                <p>{{ msg1 }}</p>
            </div>
            <div style="display:flex; justify-content: space-evenly; flex-wrap: wrap;">
              <card id="card">
                <div id="cardImage">
                    <avatar type="image" size="large" shape="circle">
                        <vue-feather type="message-square" class="feather-32"></vue-feather>
                    </avatar>
                </div>
                <div style="margin-bottom: 4px;text-align: center;height:60px;">
                    <cardHeader style="background: transparent; border-style: none;">                                    
                            <div style="margin-bottom: 4px;text-align: center;">
                                <cardTitle id="cardHeader">Chat</cardTitle>
                            </div>
                    </cardHeader>
                </div>
                <cardSubtitle id="card-subtitle">
                  Click on the button below to place an order
                </cardSubtitle>
                <div style="margin-bottom: 4px;text-align: center;">
                    <cardAction style="text-align: center;">                                    
                            <kbutton :theme-color="'primary'" @click="() => btnClick('Chat')">Chat</kbutton>
                    </cardAction>
                </div>
              </card>
              <card id="card">
                <div id="cardImage">
                    <avatar type="image" size="large" shape="circle">
                        <vue-feather type="mail" class="feather-32"></vue-feather>
                    </avatar>
                </div>
                <div style="margin-bottom: 4px;text-align: center;height:60px;">
                    <cardHeader style="background: transparent; border-style: none;">                                    
                            <div style="margin-bottom: 4px;text-align: center;">
                                <cardTitle id="cardHeader">Email Customer Service</cardTitle>
                            </div>
                    </cardHeader>
                </div>
                <cardSubtitle id="card-subtitle">
                  <p>Click on the button below to place an order</p>
                  <div v-if="env">
                    <p>{{configData.configProd.emailCustomerService}}</p>
                  </div> 
                  <div v-else>
                    <p>{{configData.config.emailCustomerService}}</p>
                  </div>
                  <b>Be sure to include:</b>
                  <ol id="card-subtitle" style="text-align: left;">
                    <li>Ship-To/Customer Number if available</li>
                    <li>Contact Name</li>
                    <li>Store Name</li>
                    <li>Service Address, including Zip Code</li>
                    <li>Phone number</li>
                    <li>E-mail address</li> 
                    <li>Order Quantity (cases or units) and Item Code/Description</li>
                  </ol>
                </cardSubtitle>
                <div style="margin-bottom: 4px;text-align: center;">
                    <cardAction style="text-align: center;">                                    
                            <kbutton :theme-color="'primary'" @click="() => btnClick('Email Customer Service')">Email Customer Service</kbutton>
                    </cardAction>
                </div>
              </card>
              <card id="card">
                <div id="cardImage">
                    <avatar type="image" size="large" shape="circle">
                        <vue-feather type="mail" class="feather-32"></vue-feather>
                    </avatar>
                </div>
                <div style="margin-bottom: 4px;text-align: center;height:60px;">
                    <cardHeader style="background: transparent; border-style: none;">                                    
                            <div style="margin-bottom: 4px;text-align: center;">
                                <cardTitle id="cardHeader">Email about Service Issues</cardTitle>
                            </div>
                    </cardHeader>
                </div>
                <cardSubtitle id="card-subtitle">
                  <p>Click on the button below to email about service issues at</p>
                  
                  <div v-if="env">
                    <p>{{configData.configProd.emailServiceIssue}}</p>
                  </div> 
                  <div v-else>
                    <p>{{configData.config.emailServiceIssue}}</p>
                  </div>
                  <b>Be sure to include:</b>
                  <ol id="card-subtitle" style="text-align: left;">
                    <li>Ship-To/Customer Number if available</li>
                    <li>Contact Name</li>
                    <li>Store Name</li>
                    <li>Service Address, including Zip Code</li>
                    <li>Phone number</li>
                    <li>E-mail address</li>
                    <li>Service Concern Description</li> 
                  </ol>
                </cardSubtitle>
                <div style="margin-bottom: 4px;text-align: center;">
                    <cardAction style="text-align: center;">                                    
                            <kbutton :theme-color="'primary'" @click="() => btnClick('Email about Service Issues')">Email about Service Issues</kbutton>
                    </cardAction>
                </div>
              </card>        
            </div>
        </div>        
    </div>
</div>
</template>

<script>
import {
  Card,
  CardHeader,
  CardTitle,
  CardSubtitle,
} from "@progress/kendo-vue-layout";
import { Button } from '@progress/kendo-vue-buttons';
import VueFeather from 'vue-feather';
import configData  from "./../../public/custom-config.json";

export default {    
  name: 'ContactPage',
  created(){
      document.title = "Contact Us"
  },
  props: {
    msg1: String
  },
  components: {
      'card': Card,
      'cardHeader': CardHeader,
      'cardTitle': CardTitle,
      'cardSubtitle': CardSubtitle,
      'kbutton': Button,
      'vue-feather': VueFeather
  },
  methods: {        
      btnClick: function(buttonText) {            
          console.log(buttonText);
          if(buttonText == "Chat"){
            window.open("https://home-c39.nice-incontact.com/incontact/chatclient/chatclient.aspx?poc=06c4cd08-a63d-4e5f-85c9-91e88db5b66b&bu=4601711", "_blank"); 
          }
          if(buttonText == "Email Customer Service"){
            if (process.env.NODE_ENV == 'production') {
              let mailtoText = "mailto:" + configData.configProd.emailCustomerService + "?subject=Customer Service";
              window.open(mailtoText);
              console.log(mailtoText);
            } else {
              let mailtoText = "mailto:" + configData.config.emailCustomerService + "?subject=Customer Service";
              window.open(mailtoText);
              console.log(mailtoText);
            }
          }
          if(buttonText == "Email about Service Issues"){
            if (process.env.NODE_ENV == 'production') {
              let mailtoText = "mailto:" + configData.configProd.emailServiceIssue + "?subject=Service Issue";
              window.open(mailtoText);
              console.log(mailtoText);
            } else {
              let mailtoText = "mailto:" + configData.config.emailServiceIssue + "?subject=Service Issue";
              window.open(mailtoText);
              console.log(mailtoText);
            }
          }
      }
  },
  data(){
    return {
      configData
    }
  },
  computed: {
    env() {
      console.log (process.env.NODE_ENV);
      return process.env.NODE_ENV === 'production' ? true : false
    }
  }    
}; 
</script>

<style>
.pageHeader {
  font-size: 1.5rem;
  font-weight: 700 !important;
  line-height: 1.2;
}
#cardHeader {
  margin-bottom: 4px;
  text-align: center;
  font-family: 'ITC Avant Garde Medium',sans-serif;
  font-weight: 700;
  font-size: 1rem;
}
#background-image-fullscreen {
    width: 100%;
    background-size: cover;
    height: 100%;
    background-position: center;
}
.k-card {
    padding: 1.5rem;
}
#card-subtitle {
    height: 380px; 
    max-width: 100%;
    font-size: 1rem; 
    text-align: left; 
    font-family: myriad-pro, sans-serif; 
    font-weight: 500; 
    line-height: 1.5rem;
    color: #424242;
}
#card {
width: 350px;
height: 550px; 
box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, .1); 
margin-top:15px;
border-radius: 30px 8px 8px 8px;
}
.feather-32{
width: 32px;
height: 32px;
}
.field_item {    
    padding: 0 0.625rem;
}
.crp-content .crp-login .crp-login-panel {
  color: #002f6c !important;
  border-radius: 10px;
  padding-top: 15px !important;
  text-align: left !important;
  padding-left: 50px;
}
.login-form {
  position: relative;
  width: 350px;
  text-align: center;
}
.crp-content {
  margin: 0 15px 10px 15px;
}
.initial-credentials-panel {
  margin-top: -40px;
}
.justify-content-center {
  -ms-flex-pack: center !important;
  justify-content: center !important;
}

.login-background {
  position: absolute;
  top: 118px;
  left: 0;
  right: 0;
  bottom: 0;
  /*background-color: #002f6c;*/
  text-align: center;
}
.login-background .image-fullscreen {
  width: 100%;
  background-size: cover;
  height: 100%;
  background-position: center;
}
.fixed-top {
  background-color: #002f6c;
  padding-top: 10px;
  padding-bottom: 10px;
  color: #fff;
  align-items: center;
}
.brandLogo {
  display: inline-block;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-left: -15px;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
}
.brandLogo img {
  max-width: 150px;
  max-height: 50px;
}
.site-header {  
  padding: 0.5rem 1rem;
}
.crp-topnav {
    margin-top: 40px;
    padding: 0.5rem 1rem;
}
.k-button-solid-primary {
  border-color: #00539b !important;
  color: white;
  background-color: #00539b !important;
  background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.02));
  width: 200px;
}
.vue-feather{
  color: #00539b !important;
}
</style>
